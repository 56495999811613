import React, { useState } from "react";
import { SketchPicker, BlockPicker, SwatchesPicker } from "react-color";

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import { Button, CircularProgress, Grid, TextareaAutosize } from "@mui/material";
import Radio from "@mui/material/Radio";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function JobForm({
  loading,
  handleChangeFontSize,
  background,
  handleChangeComplete,
  details,
  handleChange,
  submitHandler,
}) {
  const [selectedValue, setSelectedValue] = React.useState("a");

  const [fonts, setFonts] = useState([
    { value: "h5" },
    { value: "subtitle1" },
    { value: "subtitle2" },
    { value: "h4" },
  ]);
  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <form onSubmit={submitHandler}>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <MDBox mb={2}>
            <MDInput
              value={details?.title}
              type="string"
              name="title"
              required
              InputLabelProps={{ shrink: details?.title }}
              onChange={handleChange}
              label="Title"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <InputLabel sx={{ mb: 2 }}>Description</InputLabel>
            <TextareaAutosize
              minRows={5}
              style={{
                border: "1px solid gray",
                color: "#495057",
                padding: "10px",
                borderRadius: "8px",
                width: "100%",
              }}
              value={details?.description}
              type="string"
              name="description"
              required
              InputLabelProps={{ shrink: details?.description }}
              onChange={handleChange}
              label="Description"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <InputLabel sx={{ mb: 2 }}>Requirement</InputLabel>
            <TextareaAutosize
              minRows={5}
              style={{
                border: "1px solid gray",
                color: "#495057",
                padding: "10px",
                borderRadius: "8px",
                width: "100%",
              }}
              value={details?.requirements}
              type="string"
              name="requirements"
              required
              InputLabelProps={{ shrink: details?.requirements }}
              onChange={handleChange}
              label="Requirements"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              value={details?.objective}
              InputLabelProps={{ shrink: details?.objective }}
              type="string"
              name="objective"
              // required
              onChange={handleChange}
              label="Objective"
              fullWidth
            />
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="string"
              name="pay_rate"
              required
              InputLabelProps={{ shrink: details?.pay_rate }}
              value={details?.pay_rate}
              onChange={handleChange}
              label="Rate"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            {/* <FormControl
                  sx={(theme) => ({
                    [theme.breakpoints.down("md")]: { width: "100%" },
                    width: "43%",
                    minWidth: 120,
                  })}
                  size="small"
                > */}
            <InputLabel sx={{ mb: 2 }} id="fromList">
              Job Type
            </InputLabel>
            <Select
              required
              labelId="Job Type"
              id="job_type"
              sx={{ p: 2, color: "red" }}
              name="job_type"
              value={details?.job_type}
              fullWidth
              onChange={handleChange}
            >
              <MenuItem value="Full time">Full time</MenuItem>
              <MenuItem value="Part time">Part time</MenuItem>
            </Select>
            {/* </FormControl> */}
          </MDBox>

          <MDBox mb={2}>
            <MDInput
              type="string"
              name="location"
              required
              InputLabelProps={{ shrink: details?.location }}
              value={details?.location}
              onChange={handleChange}
              label="Location"
              fullWidth
            />
          </MDBox>

          <LoadingButton
            fullWidth
            loadingIndicator={<CircularProgress size={25} />}
            loading={loading}
            sx={{ borderRadius: 0.3, mt: 3 }}
            disableElevation
            style={{ backgroundColor: "#4CAF50", color: "white" }}
            variant="contained"
            type="submit"
          >
            Save
          </LoadingButton>
        </MDBox>
      </MDBox>
    </form>
  );
}

export default JobForm;
JobForm.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};
