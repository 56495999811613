import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from "@mui/base/TablePaginationUnstyled";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";

//import mui
import {
  Box,
  Card,
  Avatar,
  Grid,
  Input,
  InputAdornment,
  Chip,
  TextField,
  Badge,
  Stack,
  Backdrop,
  CircularProgress,
  Typography,
  IconButton,
  Button,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
//import icons
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import { generalPostApi } from "services";
import UserProgessBar from "components/UserProgressBar";
import { fDateTime } from "utilities";
import { stringToColor } from "utilities";
import CourseForm from "components/CourseForm";

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const allTransactionData = [
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
];

const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
       
        font-size: 0.875rem;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
        text-align: left;
        padding: 6px;
      
    }
    

    th {
        background-color:#4CAF50;
        border-color:#4CAF50;
        color:white;
      
    }
    `
);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Courses() {
  const [page, setPage] = React.useState(0);
  const [openAddCourse, setOpenAddCourse] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState();
  const [dep, setDep] = useState(1);

  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const pullUsersHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, courses, msg },
      } = await generalPostApi({
        code: 122,
        api: 110,
        data: { page_number: 1, company_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setUsers(courses);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  const changeHandler = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpenAddCourse(true);
  };

  const handleClose = () => {
    setOpenAddCourse(false);
  };
  const resetForm = () => {
    const obj = details;
    Object.keys(obj).forEach((key) => {
      obj[key] = "";
    });
    // obj.operator_id = userData.operator_id;
    setDetails({ ...obj });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    handleClose();
    try {
      const {
        data: { code, msg },
      } = await generalPostApi({
        code: 124,
        api: 110,
        data: {
          company_id: userData.user_id,
          name: details.course_name,
          url: details.course_url,
          ...details,
        },
      });
      if (code === 200) {
        resetForm();
        setLoading(false);
        setDep(dep + 1);
        enqueueSnackbar(msg, { variant: "success" });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  useEffect(() => {
    validateAdmin(userData) ? pullUsersHandler() : navigate("/authentication/sign-in");
  }, [dep]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ background: "white", py: 2, my: 3 }}>
        <Stack sx={{ mt: 3, ml: 3 }}>
          <Typography sx={{ mt: 3 }} variant="h3">
            <Badge color="secondary" badgeContent={users.length} max={999}>
              <b>Courses overview</b>{" "}
            </Badge>
          </Typography>
        </Stack>
        <Grid container spacing={2} sx={{ mt: 1, mx: 1 }}>
          <Grid item sx={6} md={4}>
            <Card
              sx={{
                p: 2,
                borderRadius: "5px",
                border: "1px solid  #E9EEF4",
                boxShadow: "none",
                height: "100%",
              }}
            >
              <Stack direction="column" alignItems="flex-start">
                <Typography sx={{ mb: 2 }} variant="subtitle2">
                  <b> Total Courses</b>
                </Typography>
                <Stack
                  sx={{ width: "100%" }}
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">{users.length}</Typography>
                  <Chip sx={{ height: "100%", px: 0 }} color="success" label="100%" />
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    As of Today
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item sx={6} md={4}>
            <Card
              sx={{
                p: 2,
                borderRadius: "5px",
                border: "1px solid  #E9EEF4",
                boxShadow: "none",
                height: "100%",
              }}
            >
              <Stack direction="column" alignItems="flex-start">
                <Typography sx={{ mb: 2 }} variant="subtitle2">
                  <b> Active Courses</b>
                </Typography>
                <Stack
                  sx={{ width: "100%" }}
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">{users.length}</Typography>
                  <Chip
                    sx={{ background: "rgba(76, 175, 80, 0.2)", height: "100%", px: 0 }}
                    label="100%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    As of Today
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item sx={6} md={3}>
            <Card
              sx={{
                p: 2,
                borderRadius: "5px",
                border: "1px solid  #E9EEF4",
                boxShadow: "none",
                height: "100%",
              }}
            >
              <Stack direction="column" alignItems="flex-start">
                <Typography sx={{ mb: 2 }} variant="subtitle2">
                  <b> Inactive Courses</b>
                </Typography>
                <Stack
                  sx={{ width: "100%" }}
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">0</Typography>
                  <Chip
                    sx={{ background: "rgba(240, 52, 52, 0.2)", height: "100%", px: 0 }}
                    label="0%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    As of Today
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <Stack sx={{ mx: 3 }} direction="row" justifyContent="space-between">
          <Button
            onClick={handleClickOpen}
            sx={{ borderRadius: 0.3, mt: 3 }}
            disableElevation
            style={{ backgroundColor: "#4CAF50", color: "white" }}
            variant="contained"
          >
            ADD COURSE
          </Button>
          <div>
            <Input
              sx={{
                mt: 3,
                // backgroundColor: "#F3F3F3",
                borderRadius: "5px",
                padding: "0px 10px 0px 10px",
                border: "1px solid #E9EEF4",
              }}
              placeholder="Search"
              disableUnderline
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </div>
        </Stack>
        <Root sx={{ width: "96%", maxWidth: "100%", m: 3 }}>
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                <th>title</th>

                <th>category</th>
                <th>Skills</th>

                <th>registered</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ borderColor: "#FFFFFF #FFFFFF #FFFFFF #F6A414" }}>
              {users.length > 0 ? (
                users.map((item, i) => {
                  const { course_id, course_name, category, skills, descrption, registered } = item;
                  return (
                    <tr>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,
                          fontStyle: "normal",
                        }}
                      >
                        {course_name}
                      </td>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        <Chip
                          avatar={<Avatar alt="Natacha" src={category?.icon_url} />}
                          variant="outlined"
                          sx={{ color: stringToColor(category?.name), height: "100%", px: 0 }}
                          label={category?.name}
                        />
                      </td>

                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        {skills}
                      </td>

                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        {fDateTime(registered)}
                      </td>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        <Stack direction="row" justifyContent="center">
                          <IconButton href={`/courseProfile/${course_id} `} size="small">
                            <MoreVertIcon />
                          </IconButton>
                        </Stack>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <Stack direction="row">NO Courses</Stack>
              )}
            </tbody>
            <tfoot></tfoot>
          </table>
        </Root>
      </Box>
      <Footer />

      <Backdrop
        sx={{ color: "#4CAF50", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* add course dialog  */}

      <Dialog
        maxWidth="md"
        fullWidth
        scroll="body"
        open={openAddCourse}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>ADD COURSE</DialogTitle>
        <DialogContent>
          <CourseForm
            loading={loading}
            submitHandler={submitHandler}
            handleChange={changeHandler}
            details={details}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: 0.3, mt: 3 }}
            disableElevation
            style={{ backgroundColor: "#4CAF50", color: "white" }}
            variant="contained"
            onClick={handleClose}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

      {/* end add course dialog */}
    </DashboardLayout>
  );
}
