/* eslint-disable camelcase */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import { Box, Chip, Typography } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ClearIcon from "@mui/icons-material/Clear";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import TodayIcon from "@mui/icons-material/Today";
import WcIcon from "@mui/icons-material/Wc";
import CakeIcon from "@mui/icons-material/Cake";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// Notistack
import { useSnackbar } from "notistack";

// icons
import EditIcon from "@mui/icons-material/Edit";

// API
import { generalPostApi } from "services";
import SubscriberUserForm from "components/SubscriberUserForm";
import { fDateTime } from "utilities";

function CompanyComponent({ details }) {
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  console.log("ss", id);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(details);

  return (
    <Box sx={{ mt: 2, background: "white", p: 2 }}>
      <Stack sx={{ mt: 2 }} spacing={2} direction="row">
        <Chip
          sx={{ borderRadius: "5px", background: "#ffa500", height: "100%", px: 0 }}
          label="Location"
        />
        <Typography variant="subtitle2" sx={{ color: "#7b809a", fontSize: size.sm }}>
          {user?.address} ,
        </Typography>
      </Stack>
      <Typography variant="h4" sx={{ mt: 2 }}>
        <b>{user?.name}</b>
      </Typography>
      <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
        <Stack spacing={1} direction="row">
          <TodayIcon />
          <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
            {user?.registered}
          </Typography>
        </Stack>

        {/* <Stack spacing={1} direction="row">
             <WcIcon />
             <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
               {user?.gender}
             </Typography>
           </Stack>
 
           <Stack spacing={1} direction="row">
             <CakeIcon />
             <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
               {user?.age}
             </Typography>
           </Stack> */}
      </Stack>
      <Divider sx={{ backgroundColor: "green" }} variant="fullWidth" />

      <Grid sx={{ mt: 2 }} container>
        <Grid item xs={12} md={12}>
          <Stack spacing={2} direction="column">
            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={1} alignItems="center" direction="row">
                <LocalPhoneIcon sx={{ color: "#7b809a" }} />
                <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                  Tel
                </Typography>
              </Stack>
              <Typography variant="subtitle2">{user?.phone_number}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={1} alignItems="center" direction="row">
                <MailOutlineIcon sx={{ color: "#7b809a" }} />
                <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                  Email
                </Typography>
              </Stack>
              <Typography variant="subtitle2">{user?.email}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={1} alignItems="center" direction="row">
                <SellOutlinedIcon sx={{ color: "#7b809a" }} />
                <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                  Status
                </Typography>
              </Stack>
              <Typography variant="subtitle2">Active</Typography>
            </Stack>
            <Stack spacing={1} direction="column">
              <Stack spacing={1} alignItems="center" direction="row">
                <InfoOutlinedIcon sx={{ color: "#7b809a" }} />
                <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                  Description
                </Typography>
              </Stack>
              <Box sx={{ borderRadius: "5px", p: 2, background: "#f1f2f4" }}>
                <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                  {user?.description}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid sx={{ mt: 1 }} item xs={12} md={12}>
          <Stack spacing={2} direction="row">
            <div>
              <Stack direction="row">
                <Stack spacing={1} alignItems="center" direction="row">
                  <Typography variant="subtitle2">Website: </Typography>
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    {user?.website}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack spacing={1} alignItems="center" direction="row">
                  <Typography variant="subtitle2"> District: </Typography>
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    {user?.disctrict}
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row">
                <Stack spacing={1} alignItems="center" direction="row">
                  <Typography variant="subtitle2"> City: </Typography>
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    {user?.city}
                  </Typography>
                </Stack>
              </Stack>
            </div>

            <Stack sx={{ width: "100%" }} direction="row" justifyContent="flex-end">
              <img style={{ width: "100px" }} src={user?.logo_url} />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CompanyComponent;
