import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from "@mui/base/TablePaginationUnstyled";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";

//import mui
import {
  FormControl,
  Box,
  Card,
  Grid,
  FilledInput,
  Input,
  InputAdornment,
  InputLabel,
  Chip,
  TextField,
  Badge,
  MenuItem,
  Select,
  Stack,
  Typography,
  LinearProgress,
  Button,
  IconButton,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
//import icons
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Notistack
import { useSnackbar } from "notistack";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import { generalPostApi } from "services";
import UserProgessBar from "components/UserProgressBar";
import { fDateTime } from "utilities";
import JobForm from "components/JobForm";

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const allTransactionData = [
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
  {
    date: "26-08-2021",
    user: "Peter",
    client: "Rescue Ai",
    account: "255654335651",
    status: "success",
    amount: "120,000",
  },
];

const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
       
        font-size: 0.875rem;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
        text-align: left;
        padding: 6px;
      
    }
    

    th {
        background-color:#4CAF50;
        border-color:#4CAF50;
        color:white;
      
    }
    `
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Jobs() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openAddJob, setOpenAddJob] = useState(false);
  const [details, setDetails] = useState();
  const [dep, setDep] = useState(1);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeHandler = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const {
        data: { code, msg },
      } = await generalPostApi({
        code: 120,
        api: 110,
        data: {
          company_id: userData.user_id,
          category_id: "4054ff30640840c480e4a460a298b052",
          ...details,
        },
      });
      if (code === 200) {
        resetForm();
        setLoading(false);
        setDep(dep + 1);
        enqueueSnackbar(msg, { variant: "success" });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const pullUsersHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, jobs, msg },
      } = await generalPostApi({
        code: 111,
        api: 110,
        data: { page_number: 1, company_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setUsers(jobs);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpenAddJob(true);
  };

  const handleClose = () => {
    setOpenAddJob(false);
  };
  const resetForm = () => {
    const obj = details;
    Object.keys(obj).forEach((key) => {
      obj[key] = "";
    });
    // obj.operator_id = userData.operator_id;
    setDetails({ ...obj });
  };

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    validateAdmin(userData) ? pullUsersHandler() : navigate("/authentication/sign-in");
  }, [dep]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ background: "white", my: 3 }}>
        <Stack sx={{ mt: 3, ml: 3 }}>
          <Typography sx={{ mt: 3 }} variant="h3">
            <Badge color="secondary" badgeContent={users.length} max={999}>
              <b>Jobs overview</b>{" "}
            </Badge>
          </Typography>
        </Stack>
        <Grid container spacing={2} sx={{ mt: 1, mx: 1 }}>
          <Grid item sx={6} md={4}>
            <Card
              sx={{
                p: 2,
                borderRadius: "5px",
                border: "1px solid  #E9EEF4",
                boxShadow: "none",
                height: "100%",
              }}
            >
              <Stack direction="column" alignItems="flex-start">
                <Typography sx={{ mb: 2 }} variant="subtitle2">
                  <b> Total Jobs</b>
                </Typography>
                <Stack
                  sx={{ width: "100%" }}
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">{users.length}</Typography>
                  <Chip sx={{ height: "100%", px: 0 }} color="success" label="100%" />
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    As of Today
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item sx={6} md={4}>
            <Card
              sx={{
                p: 2,
                borderRadius: "5px",
                border: "1px solid  #E9EEF4",
                boxShadow: "none",
                height: "100%",
              }}
            >
              <Stack direction="column" alignItems="flex-start">
                <Typography sx={{ mb: 2 }} variant="subtitle2">
                  <b> Active Jobs</b>
                </Typography>
                <Stack
                  sx={{ width: "100%" }}
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">{users.length}</Typography>
                  <Chip
                    sx={{ background: "rgba(76, 175, 80, 0.2)", height: "100%", px: 0 }}
                    label="100%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    As of Today
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>

          <Grid item sx={6} md={3}>
            <Card
              sx={{
                p: 2,
                borderRadius: "5px",
                border: "1px solid  #E9EEF4",
                boxShadow: "none",
                height: "100%",
              }}
            >
              <Stack direction="column" alignItems="flex-start">
                <Typography sx={{ mb: 2 }} variant="subtitle2">
                  <b> Inactive Jobs</b>
                </Typography>
                <Stack
                  sx={{ width: "100%" }}
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5">0</Typography>
                  <Chip
                    sx={{ background: "rgba(240, 52, 52, 0.2)", height: "100%", px: 0 }}
                    label="0%"
                  />
                </Stack>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    As of Today
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
        <Stack sx={{ mx: 3 }} direction="row" justifyContent="space-between">
          <Button
            onClick={handleClickOpen}
            sx={{ borderRadius: 0.3, mt: 3 }}
            disableElevation
            style={{ backgroundColor: "#4CAF50", color: "white" }}
            variant="contained"
          >
            ADD JOB
          </Button>
          <div>
            <Input
              sx={{
                mt: 3,
                // backgroundColor: "#F3F3F3",
                borderRadius: "5px",
                padding: "0px 10px 0px 10px",
                border: "1px solid #E9EEF4",
              }}
              placeholder="Search"
              disableUnderline
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </div>
        </Stack>
        <Root sx={{ width: "96%", maxWidth: "100%", m: 2 }}>
          <table aria-label="custom pagination table">
            <thead>
              <tr>
                <th>title</th>
                <th>type</th>
                <th>category</th>
                <th>Status</th>
                <th>company</th>
                <th>Location</th>
                <th>Deadline</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ borderColor: "#FFFFFF #FFFFFF #FFFFFF #F6A414" }}>
              {users.length > 0 ? (
                users.map((item, i) => {
                  const { job_id, title, job_type, company, location, deadline } = item;
                  return (
                    <tr>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,
                          fontStyle: "normal",
                        }}
                      >
                        {title}
                      </td>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        {job_type}
                      </td>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        {company.name}
                      </td>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        {location}
                      </td>

                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        <Chip
                          variant="outlined"
                          sx={{ color: "green", height: "100%", px: 0 }}
                          label="active"
                        />
                      </td>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        {location}
                      </td>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        {deadline}
                      </td>
                      <td
                        style={{
                          background: "#FFFFFF",
                          fontWeight: 500,

                          fontStyle: "normal",
                        }}
                      >
                        <Stack direction="row" justifyContent="center">
                          <IconButton href={`/jobProfile/${job_id} `} size="small">
                            <MoreVertIcon />
                          </IconButton>
                        </Stack>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <Stack direction="row">NO Jobs</Stack>
              )}
            </tbody>
            <tfoot></tfoot>
          </table>
        </Root>
      </Box>
      <Footer />

      {/* start add job */}

      <Dialog
        maxWidth="md"
        fullWidth
        scroll="body"
        open={openAddJob}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>ADD JOB</DialogTitle>
        <DialogContent>
          <JobForm
            loading={loading}
            submitHandler={submitHandler}
            handleChange={changeHandler}
            details={details}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ borderRadius: 0.3, mt: 3 }}
            disableElevation
            style={{ backgroundColor: "#4CAF50", color: "white" }}
            variant="contained"
            onClick={handleClose}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

      {/* end add job */}
    </DashboardLayout>
  );
}
