import React from "react";

// @mui material components
import { Grid, Avatar, Card, Box, Typography, Stack, Divider, Chip } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";

function VideoCard({ src }) {
  const { size } = typography;
  console.log("sda", src);
  let urlArray = src?.split("/");
  console.log(urlArray);
  return (
    <Card>
      <iframe
        style={{ borderRadius: "10PX" }}
        width="100%"
        height="425px"
        src={`https://www.youtube.com/embed/${urlArray[3]}`}
        allowfullscreen
      ></iframe>
    </Card>
  );
}

export default VideoCard;
