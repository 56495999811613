import React from "react";

// @mui material components
import { Grid, Card, Box, Typography, Stack, Divider, Chip } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";

// icons
// import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

import { stringToColor } from "utilities";

function LatestJobCard({ logo, category, title, jobType, location }) {
  const { size } = typography;
  return (
    <Card
      sx={{
        p: 2,
        borderRadius: "5px",
        border: "1px solid #E9EEF4",
        boxShadow: "none",
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <img style={{ width: "40px" }} src={logo} />
          <Chip
            sx={{ background: stringToColor(category), height: "100%", px: 0 }}
            label={category}
          />
        </Stack>

        <Typography variant="subtitle2">
          <b>{title}</b>
        </Typography>
        <Typography sx={{ fontSize: size.sm }} color="success" variant="subtitle2">
          {jobType}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontSize: size.sm, color: "#7b809a" }}>
          {location}
        </Typography>
      </Stack>
    </Card>
  );
}

export default LatestJobCard;
