import React from "react";

// @mui material components
import { Grid, Card, Box, Typography, Stack, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";

// icons
// import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

function StatisticsCard({ stat, title, icon }) {
  const { size } = typography;
  return (
    <Stack alignItems="center" spacing={2} direction="row">
      <div
        style={{
          width: "3vw",
          height: "3vw",
          borderRadius: "50%",
          background: "rgba(76, 175, 80, 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </div>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="black"
        fontSize={size.md}
      >
        <b>{stat}</b>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="black  "
        fontSize={size.sm}
      >
        {title}
      </MDBox>
    </Stack>
  );
}

export default StatisticsCard;
