/* eslint-disable camelcase */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import { Box, Chip, Typography, Card, CircularProgress, Backdrop } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ClearIcon from "@mui/icons-material/Clear";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import TodayIcon from "@mui/icons-material/Today";
import WcIcon from "@mui/icons-material/Wc";
import CakeIcon from "@mui/icons-material/Cake";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";

import VideoCard from "components/VideoCard";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// Notistack
import { useSnackbar } from "notistack";

// icons
import EditIcon from "@mui/icons-material/Edit";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";

//images
import sadIllustration from "../../assets/images/sadIllustration.png";

// API
import { generalPostApi } from "services";
import SubscriberUserForm from "components/SubscriberUserForm";
import { fDateTime } from "utilities";
import CourseForm from "components/CourseForm";
import { FlashOnOutlined } from "@mui/icons-material";
import LoadingComponent from "components/LoadingComponent";

function CourseProfile() {
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  console.log("ss", id);

  const [loading, setLoading] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const [selectedCourse, setUser] = useState();
  const [details, setDetails] = useState();
  const [enableEditing, setEnableEditing] = useState(false);
  const [dep, setDep] = useState(1);

  const pullUsersHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, courses, msg },
      } = await generalPostApi({
        code: 122,
        api: 110,
        data: { page_number: 1, company_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setUser(courses.filter((item) => item.course_id === id)[0]);
        setDetails({
          ...courses.filter((item) => item.course_id === id)[0],
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  const editHandler = () => {
    setEnableEditing(!enableEditing);
  };

  const changeHandler = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const {
        data: { code, msg },
      } = await generalPostApi({
        code: 126,
        api: 110,
        data: {
          company_id: userData.user_id,
          name: details.course_name,
          url: details.course_url,
          ...details,
        },
      });
      if (code === 200) {
        setLoading(false);
        setDep(dep + 1);
        enqueueSnackbar(msg, { variant: "success" });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  //  states

  useEffect(() => {
    if (validateAdmin(userData)) {
      pullUsersHandler();
    } else {
      navigate("/authentication/sign-in");
    }
  }, [dep]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ mt: 2, background: "white", p: 2 }}>
        <Grid style={{ transitionDelay: "1s" }} container>
          <Grid item sm={12} md={enableEditing ? 8 : 12}>
            <Card sx={{ p: 2, height: "100%" }}>
              {!loading ? (
                selectedCourse ? (
                  <Stack spacing={2} direction="column">
                    <VideoCard src={selectedCourse.course_url} />
                    <Stack spacing={5} direction="row">
                      <Stack spacing={1} direction="row" alignItems="center">
                        <TodayIcon />
                        <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                          {selectedCourse.registered}
                        </Typography>
                      </Stack>
                      <Stack spacing={1} alignItems="center" direction="row">
                        <SellOutlinedIcon sx={{ color: "#7b809a" }} />
                        <Typography
                          sx={{ color: "#7b809a", fontSize: size.sm }}
                          variant="subtitle2"
                        >
                          {selectedCourse.category}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Typography variant="h4">
                      <b>{selectedCourse.course_name}</b>
                    </Typography>

                    <Typography sx={{ fontSize: size.sm, color: "#7b809a" }} variant="subtitle2">
                      {selectedCourse.description}
                    </Typography>

                    <Stack direction="column">
                      <Typography variant="subtitle2">
                        <b>skills</b>
                      </Typography>
                      <Typography sx={{ color: "#7b809a" }} variant="subtitle2">
                        {selectedCourse.skills}
                      </Typography>
                    </Stack>

                    <Stack direction="column">
                      <TrackChangesIcon sx={{ color: "black" }} />
                      <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                        {selectedCourse.objective}
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end">
                      <Chip
                        color="success"
                        onClick={() => navigate(`/users?course=${selectedCourse.course_id}`)}
                        label={"COURSE USERS"}
                      />
                      <Chip
                        color="warning"
                        onClick={editHandler}
                        label={enableEditing ? "FULL SCREEN" : "EDIT"}
                      />
                    </Stack>
                  </Stack>
                ) : (
                  <Stack direction="column" justifyContent="center">
                    <Typography variant="h4">NO COURSE SELECTED</Typography>

                    <img
                      style={{ width: "100%", height: "auto", objectFit: "cover" }}
                      src={sadIllustration}
                    />
                  </Stack>
                )
              ) : null}
            </Card>
          </Grid>
          {enableEditing ? (
            <Grid item sm={12} md={4}>
              <Typography sx={{ mx: 3 }} variant="h6">
                EDIT COURSE
              </Typography>
              <CourseForm
                loading={loading}
                submitHandler={submitHandler}
                handleChange={changeHandler}
                details={details}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>

      <Footer />

      <Backdrop
        sx={{ color: "#4CAF50", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}

export default CourseProfile;
