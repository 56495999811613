import { useState } from "react";

// react-router-dom components

// @mui material components

import MDButton from "components/MDButton";

import LoadingButton from "@mui/lab/LoadingButton";
import { Card, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// API
import { signinApi } from "services";

// Notistack
import { useSnackbar } from "notistack";

function Basic() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  //  states

  const [processLoading, setProcessLoading] = useState(false);
  const [details, setDetails] = useState({ role: "company" });

  // functions

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  // API calls
  const signinHandler = async (e) => {
    e.preventDefault();
    if (details.email === undefined || details.email === "" || details.email === null) {
      enqueueSnackbar("Please fill all the fields", { variant: "error" });
    } else {
      try {
        setProcessLoading(true);
        const {
          data: { code, user_details, msg },
        } = await signinApi({
          code: 100,
          api: 110,
          data: { email: details.email, password: details.password },
        });
        if (code === 200) {
          enqueueSnackbar("Verified!");
          setProcessLoading(false);

          if (user_details.role === details.role) {
            sessionStorage.setItem("userData", JSON.stringify(user_details));
            navigate("/dashboard");
          } else {
            enqueueSnackbar("Wrong User", { variant: "error" });
          }
        } else {
          enqueueSnackbar(msg, { variant: "error" });
          setProcessLoading(false);
        }
      } catch (error) {
        setProcessLoading(false);
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <form onSubmit={signinHandler}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  name="email"
                  required
                  onChange={handleChange}
                  label="Email"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  name="password"
                  required
                  onChange={handleChange}
                  label="Password"
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                {/* <FormControl
                  sx={(theme) => ({
                    [theme.breakpoints.down("md")]: { width: "100%" },
                    width: "43%",
                    minWidth: 120,
                  })}
                  size="small"
                > */}
                <InputLabel id="fromList">Role</InputLabel>
                <Select
                  required
                  labelId="fromList"
                  id="role"
                  sx={{ p: 2, color: "red" }}
                  name="role"
                  value={details.role}
                  fullWidth
                  onChange={handleChange}
                >
                  <MenuItem value="company">Company</MenuItem>
                  <MenuItem value="instructor">instructor</MenuItem>
                </Select>
                {/* </FormControl> */}
              </MDBox>

              <MDBox mt={4} mb={1}>
                <LoadingButton
                  type="submit"
                  style={{ color: "white" }}
                  loading={processLoading}
                  // onClick={signinHandler}
                  variant="contained"
                >
                  signin
                </LoadingButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
