/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react imports
import React, { useEffect, useState } from "react";

// @mui material components
import { Grid, Card, Chip, Box, Typography, Stack, Divider } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import typography from "assets/theme/base/typography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import LoadingComponent from "components/LoadingComponent";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// icons
import ApartmentTwoToneIcon from "@mui/icons-material/ApartmentTwoTone";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
// data
import userTableData from "components/UserTableData";

// API
import { pullStatsApi } from "services";
import { generalPostApi, getStatsApi } from "../../services";
import HorizontalBarChart from "../../examples/Charts/BarCharts/HorizontalBarChart";
import StatisticsCard from "components/StatisticsCard";

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  Legend,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import LatestJobCard from "components/LatestJobCard";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([{}]);
  const [latestJobs, setLatestJobs] = useState([]);
  const { rows, columns, loadingTable } = userTableData();

  const pullStatsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, ...rest },
      } = await generalPostApi({
        code: userData.role === "instructor" ? 121 : 112,
        api: 110,
        data: { company_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);
        setStats(rest);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const pullJobsHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, jobs, msg },
      } = await generalPostApi({
        code: 114,
        api: 120,
        data: { operator_id: userData.operator_id, page_number: 1 },
      });
      if (code === 200) {
        setLoading(false);
        setLatestJobs(jobs);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const datas = [
    {
      name: "JAN",

      pv: 10,
    },
    {
      name: "FEB",
      pv: 6,
    },
    {
      name: "MAR",

      pv: 5,
    },
    {
      name: "MAY",

      pv: 12,
    },
    {
      name: "JUN",

      pv: 19,
    },
    {
      name: "JUL",

      pv: 20,
    },
    {
      name: "AUG",

      pv: 25,
    },
    {
      name: "SEP",

      pv: 37,
    },
    {
      name: "OCT",

      pv: 17,
    },

    {
      name: "NOV",

      pv: 23,
    },

    {
      name: "DEC",

      pv: 10,
    },
  ];

  const data1 = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const labelArr = [
    { label: "Full time", color: "#0088FE" },
    { label: "Part time", color: "#00C49F" },
    { label: "Contract", color: "#FFBB28" },
    { label: "Remote", color: "#FF8042" },
  ];

  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    validateAdmin(userData) ? pullStatsHandler() : navigate("/authentication/sign-in");
    pullJobsHandler();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ background: "white", my: 2 }}>
        <Card sx={{ boxShadow: "none", px: 2, py: 2, borderRadius: "5px" }}>
          <Grid container spacing={1}>
            <Grid sx={{ display: "flex" }} item xs={6} md={3}>
              <StatisticsCard
                stat={stats?.applications}
                title="Applications"
                icon={<StoreOutlinedIcon color="success" />}
              />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={3}>
              <Divider sx={{ background: "black" }} orientation="vertical" flexItem />
              <StatisticsCard
                stat={stats?.jobs}
                title="Jobs"
                icon={<StoreOutlinedIcon color="success" />}
              />
            </Grid>

            <Grid sx={{ display: "flex" }} item xs={6} md={3}>
              <Divider sx={{ background: "black" }} orientation="vertical" flexItem />
              <StatisticsCard
                stat={stats?.courses}
                title="Courses"
                icon={<StoreOutlinedIcon color="success" />}
              />
            </Grid>
            <Grid sx={{ display: "flex" }} item xs={6} md={3}>
              <Divider sx={{ background: "black" }} orientation="vertical" flexItem />
              <StatisticsCard
                stat={stats?.users}
                title="Users"
                icon={<StoreOutlinedIcon color="success" />}
              />
            </Grid>
          </Grid>
        </Card>
        {userData?.role === "instructor" || "company" ? null : (
          <Grid container spacing={2} sx={{ mt: 2, px: 2 }}>
            <Grid item sm={12} md={8}>
              <Stack spacing={2} direction="row">
                <Typography sx={{ mb: 1 }} variant="subtitle2">
                  <b>Job Statistics </b>
                </Typography>
                <Chip sx={{ height: "100%", px: 0 }} color="success" label="overview" />
              </Stack>
              <Grid container spacing={3}>
                <Grid item sx={6} md={4}>
                  <Card
                    sx={{
                      py: 2,
                      borderRadius: "5px",
                      border: "1px solid  #E9EEF4",
                      boxShadow: "none",
                      height: "100%",
                    }}
                  >
                    <Stack direction="column" alignItems="center">
                      <Typography sx={{ mb: 2 }} variant="subtitle2">
                        <b> Total jobs</b>
                      </Typography>
                      <Typography variant="h5">90</Typography>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Typography
                          sx={{ color: "#7b809a", fontSize: size.sm }}
                          variant="subtitle2"
                        >
                          This week
                        </Typography>
                        <Chip sx={{ height: "100%", px: 0 }} color="success" label="+50%" />
                      </Stack>
                    </Stack>
                  </Card>
                </Grid>
                <Grid item sx={6} md={4}>
                  <Card
                    sx={{
                      py: 2,
                      borderRadius: "5px",
                      border: "1px solid #E9EEF4",
                      boxShadow: "none",
                      height: "100%",
                    }}
                  >
                    <Stack direction="column" alignItems="center">
                      <Typography sx={{ mb: 2 }} variant="subtitle2">
                        <b>Jobs Applied</b>
                      </Typography>
                      <Typography variant="h5">90</Typography>
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Typography
                          sx={{ color: "#7b809a", fontSize: size.sm }}
                          variant="subtitle2"
                        >
                          This week
                        </Typography>
                        <Chip sx={{ height: "100%", px: 0 }} color="error" label="-10%" />
                      </Stack>
                    </Stack>
                  </Card>
                </Grid>
                <Grid item sx={6} md={4}>
                  <Card
                    variant="outlined"
                    sx={{
                      borderRadius: "5px",
                      boxShadow: "none",
                      background: "#FAF8F4",
                    }}
                  >
                    <Stack direction="row">
                      <Stack
                        sx={{ m: 1, py: 4, background: "white", width: "50%" }}
                        direction="column"
                        alignItems="center"
                      >
                        <Typography variant="h5">90</Typography>
                        <Typography sx={{ color: "#7b809a" }} variant="subtitle2">
                          Open Jobs
                        </Typography>
                      </Stack>
                      <Stack sx={{ m: 1 }} direction="row" alignItems="flex-end">
                        <Typography
                          sx={{ fontSize: size.sm, color: "#7b809a" }}
                          variant="subtitle2"
                        >
                          Details
                        </Typography>
                      </Stack>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 4 }}>
                <Grid item xs={12} md={8}>
                  <Stack spacing={1} alignItems="center" direction="row">
                    <div
                      style={{
                        width: "1vw",
                        height: "1vw",
                        borderRadius: "50%",
                        background: "green",
                      }}
                    />
                    <Typography variant="subtitle2">Companies</Typography>
                  </Stack>
                  {/* <ResponsiveContainer > */}
                  <BarChart
                    width={420}
                    height={300}
                    data={datas}
                    margin={{
                      top: 5,
                      right: 10,
                      left: 0,
                      bottom: 5,
                    }}
                    barSize={15}
                  >
                    <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar
                      style={{ borderRadius: "10px" }}
                      dataKey="pv"
                      fill="green"
                      background={{ fill: "#eee" }}
                    >
                      <div>sdas</div>
                    </Bar>
                  </BarChart>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack direction="row">
                    <Typography variant="subtitle2">
                      <b>Application Summary</b>
                    </Typography>
                  </Stack>

                  <PieChart width={400} height={120}>
                    <Pie
                      data={data1}
                      cx={100}
                      cy={100}
                      startAngle={180}
                      endAngle={0}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data1.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                  {labelArr.map((item) => {
                    return (
                      <Stack spacing={1} alignItems="center" direction="row">
                        <div
                          style={{
                            width: "1vw",
                            height: "1vw",
                            borderRadius: "50%",
                            background: item.color,
                          }}
                        />
                        <Typography
                          sx={{ fontSize: size.small, color: "#7b809a" }}
                          variant="subtitle2"
                        >
                          {item.label}
                        </Typography>
                      </Stack>
                    );
                  })}

                  {/* </ResponsiveContainer> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={4}>
              <Stack spacing={2} direction="row" justifyContent="space-between">
                <Typography sx={{ mb: 1 }} variant="subtitle2">
                  <b>Latest Jobs</b>
                </Typography>
                <Chip
                  onClick={() => navigate("/jobs")}
                  sx={{ height: "100%", px: 0 }}
                  color="success"
                  label="view more"
                />
              </Stack>
              <Stack direction="column" spacing={2}>
                {latestJobs.length > 0
                  ? latestJobs.map((item) => {
                      return (
                        <LatestJobCard
                          category={item.category.name}
                          jobType={item.job_type}
                          location={item.location}
                          title={item.title}
                          logo={item.company.logo_url}
                        />
                      );
                    })
                  : null}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
