/* eslint-disable camelcase */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import { Box, Chip, Typography, Tab, Tabs } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ClearIcon from "@mui/icons-material/Clear";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import TodayIcon from "@mui/icons-material/Today";
import WcIcon from "@mui/icons-material/Wc";
import CakeIcon from "@mui/icons-material/Cake";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PushPinIcon from "@mui/icons-material/PushPin";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import typography from "assets/theme/base/typography";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// Notistack
import { useSnackbar } from "notistack";

// icons
import EditIcon from "@mui/icons-material/Edit";

// API
import { generalPostApi } from "services";
import SubscriberUserForm from "components/SubscriberUserForm";
import { fDateTime } from "utilities";
import { stringToColor } from "utilities";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function JobProfile() {
  const { size } = typography;
  const userData = JSON.parse(sessionStorage.getItem("userData"));

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  console.log("ss", id);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  const pullUsersHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { code, jobs, msg },
      } = await generalPostApi({
        code: 111,
        api: 110,
        data: { page_number: 1, company_id: userData.user_id },
      });
      if (code === 200) {
        setLoading(false);

        setUser(jobs.filter((item) => item.job_id === id)[0]);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const validateAdmin = (userId) => {
    if (userId === undefined || userId == null) {
      navigate("authentication/sign-in");
      return false;
    } else {
      return true;
    }
  };

  //  states

  useEffect(() => {
    if (validateAdmin(userData)) {
      pullUsersHandler();
    } else {
      navigate("/authentication/sign-in");
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ mt: 2, background: "white", p: 2 }}>
        <Stack
          sx={{ width: "100%", mt: 2 }}
          spacing={2}
          justifyContent="space-between"
          direction="row"
        >
          <Typography variant="h4" sx={{ mt: 2 }}>
            <b>{user?.title} </b>
          </Typography>
          <img style={{ width: "90px" }} src={user?.company?.logo_url} />
        </Stack>
        <Stack sx={{ mt: 2 }} spacing={2} direction="row">
          <Chip
            sx={{
              borderRadius: "5px",
              opacity: "60%",
              background: "#ffa500",
              height: "100%",
              px: 0,
            }}
            label={user?.job_type}
          />
        </Stack>
        <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
          <Stack spacing={1} direction="row">
            <TodayIcon />
            <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
              {fDateTime(user?.deadline)}
            </Typography>
            <Chip
              sx={{
                borderRadius: "5px",

                height: "100%",
                px: 0,
              }}
              color="error"
              label="Deadline"
            />
          </Stack>
          <Stack spacing={1} direction="row">
            <AttachMoneyIcon />
            <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
              {user?.pay_rate}
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ backgroundColor: "green" }} variant="fullWidth" />

        <Grid sx={{ mt: 2 }} container>
          <Grid item xs={12} md={6}>
            <Stack spacing={2} direction="column">
              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={1} alignItems="center" direction="row">
                  <PushPinIcon sx={{ color: "#7b809a" }} />
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    Location
                  </Typography>
                </Stack>
                <Typography variant="subtitle2">{user?.location}</Typography>
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={1} alignItems="center" direction="row">
                  <SellOutlinedIcon sx={{ color: "#7b809a" }} />
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    Status
                  </Typography>
                </Stack>
                <Chip
                  sx={{
                    borderRadius: "5px",

                    background: "rgba(76, 175, 80, 0.2)",
                    height: "100%",
                    px: 0,
                  }}
                  label="Active"
                />
              </Stack>
              <Stack spacing={1} direction="column">
                <Stack spacing={1} alignItems="center" direction="row">
                  <InfoOutlinedIcon sx={{ color: "#7b809a" }} />
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    Category
                  </Typography>
                </Stack>
                <Box sx={{ borderRadius: "5px", p: 2, background: "rgba(76, 175, 80, 0.2)" }}>
                  <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        background: stringToColor(user?.category?.name),
                        height: "100%",
                        px: 0,
                      }}
                      label={user?.category?.name}
                    />
                    <img style={{ width: "40px" }} src={user?.category?.icon_url} />
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ px: 3, width: "100%" }}>
              <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab
                    label={
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        Description
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <Typography sx={{ fontSize: size.sm }} variant="subtitle2">
                        Requirements
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ borderRadius: "5px", p: 2, background: "rgba(76, 175, 80, 0.2)" }}>
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    {user?.description}
                  </Typography>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box sx={{ borderRadius: "5px", p: 2, background: "rgba(76, 175, 80, 0.2)" }}>
                  <Typography sx={{ color: "#7b809a", fontSize: size.sm }} variant="subtitle2">
                    {user?.requirements}
                  </Typography>
                </Box>
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </DashboardLayout>
  );
}

export default JobProfile;
